import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import { Link } from "gatsby";

// Service Images
import ServiceImage1 from "../assets/images/services-image/service-image1.png";
import ServiceImage2 from "../assets/images/services-image/service-image2.png";
import ServiceImage3 from "../assets/images/services-image/service-image3.png";
import ServiceImage4 from "../assets/images/services-image/service-image4.png";
import ServiceImage5 from "../assets/images/services-image/service-image5.png";
import ServiceImage6 from "../assets/images/services-image/service-image6.png";
import MinecraftImage from "../assets/images/jeux/minecraft-service.png";
import RustImage from "../assets/images/jeux/rust-service.png";


// Shape Images
import Shape1 from "../assets/images/shape1.png";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";

const serviceImgStyle = {
    maxHeight:'185px',
    maxWidth:'200px',
 }

const Services2 = () => (
    <Layout>
        <SEO title="Hébergement de jeux" /> 

        <Navbar />

        <PageBanner pageTitle="Serveurs de jeux" />

        <div className="ml-services-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">Les jeux disponibles</span>
                    <h2>Serveurs de jeux</h2>
                    <div className="bar"></div>
                    <p>N'hésitez pas à nous contacter pour l'ajout d'un jeu spécifique : <a href="mailto:contact@fenuahosting.com">contact@fenuahosting.com</a></p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-6">
                    <a style={{width: "100%"}} href="/serveur-minecraft">
                        <div className="single-ml-services-box">
                            <div className="image">
                                <img style={serviceImgStyle} src={MinecraftImage} alt="image" />
                            </div>
                            <h3>
                                <Link to="/serveur-minecraft">
                                    Minecraft
                                </Link>
                            </h3>
                            <p>Minecraft est un jeu de construction et de survie en monde ouvert où les joueurs peuvent explorer, créer et interagir avec un univers infini de blocs.</p>
                        </div>
                        </a>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                    <a style={{width: "100%"}} href="/serveur-rust">
                        <div className="single-ml-services-box">
                            <div className="image">
                                <img style={serviceImgStyle} src={RustImage} alt="image" />
                            </div>
                            <h3>
                                <Link to="/serveur-rust">
                                    Rust
                                </Link>
                            </h3>
                            <p>Rust est un jeu de survie multijoueur en ligne où les joueurs doivent se battre pour survivre dans un monde impitoyable et hostile en récoltant des ressources.</p>
                        </div>
                        </a>
                    </div>

                    </div>
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>

        <Footer />
    </Layout>
)

export default Services2;